import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";

import Layout from "../components/layout";
import Hero from "../components/hero";
import * as styles from "../templates/blog-post.module.css";

class OrderSuccessIndex extends React.Component {
  render() {
    const [siteInfo] = get(this.props, "data.allContentfulSiteInfo.nodes");

    return (
      <Layout location={this.props.location}>
        <Hero
          image={siteInfo.frontPageImage?.gatsbyImageData}
          title={siteInfo.title}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: siteInfo.longerText.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default OrderSuccessIndex;

export const pageQuery = graphql`
  query DonationSuccessQuery {
    allContentfulSiteInfo(
      filter: { contentful_id: { eq: "5Ite2g1cYhPUrDiMTQdk71" } }
    ) {
      nodes {
        frontPageImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
            quality: 100
          )
        }
        longerText {
          childMarkdownRemark {
            html
          }
        }
        title
      }
    }
  }
`;
